import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TFunction, useTranslation } from 'react-i18next';
import ProductService from './product.service';
import { DataTablePro as DataTable, Title } from '../common/ui-component';
import { Product, ProductCategory, ProductMedication } from './product.entity';

const getColumns = (t: TFunction<'product', undefined>): GridColDef[] => [
  {
    field: 'sku',
    headerName: t('sku'),
  },
  {
    field: 'name',
    headerName: t('name'),
  },
  {
    field: 'distributor',
    headerName: t('pharmacy'),
  },
  {
    field: 'mdiTitle',
    headerName: t('mdiTitle'),
    renderCell: (params: GridRenderCellParams<Product>) => {
      const medications = params.row?.medications;
      if (!Array.isArray(medications)) return '';
      return ((medications as ProductMedication[]) || [])
        .map((m) => m.title)
        .filter(Boolean)
        .join(', ');
    },
  },
  {
    field: 'mdiId',
    headerName: t('mdiId'),
    renderCell: (params: GridRenderCellParams<Product>) => {
      const medications = params.row?.medications;
      if (!Array.isArray(medications)) return '';
      return ((medications as ProductMedication[]) || [])
        .map((m) => ('partner_medication_id' in m ? m.partner_medication_id : m.partner_compound_id))
        .filter(Boolean)
        .join(', ');
    },
  },
  {
    field: 'categories',
    headerName: t('category'),
    renderCell: (params: GridRenderCellParams<ProductCategory[]>) => {
      if (!params.value?.length) return '';
      return params.value.some((c: ProductCategory) => c.name === 'Rx') ? 'Rx' : 'OTC';
    },
  },
  {
    field: 'created',
    headerName: t('created'),
  },
  {
    field: 'createdWithTime',
    headerName: t('createdWithTime'),
  },
];

const Products = () => {
  const { t } = useTranslation('product');
  const [fetchProducts, rProducts] = ProductService.useSearchProducts();

  return (
    <>
      <Title>{t('products')}</Title>

      <DataTable
        id="product-table"
        cols={getColumns(t)}
        fetcher={fetchProducts}
        datas={rProducts}
        toRow={(pProduct: Product) => ({ ...pProduct, createdWithTime: pProduct.created })}
        toCsvRow={(pProduct: Product) => ({
          ...pProduct,
          categories: (pProduct.categories || []).map((c) => c.name).join(', '),
        })}
        initialColumnVisibilityModel={{ createdWithTime: false }}
        checkboxSelection
      />
    </>
  );
};

export default Products;
