import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import ORDER_EN from './orders/locale/en';
import COMMON_EN from './common/locale/en';
import LOGIN_EN from './login/locale/en';
import SUBSCRIPTION_EN from './subscriptions/locale/en';
import CUSTOMER_EN from './customer/locale/en';
import ADDRESS_EN from './common/address/locale/en';
import QUESTIONS_EN from './common/questions/locale/en';
import DEAD_LETTER_EN from './dead-letter/locale/en';
import PHARMACY_EN from './pharmacy/locale/en';
import EMAIL_TRACKING_EN from './tracking-email/locale/en';
import PENDING_ORDER_EN from './pending-orders/locale/en';
import MEDICAL_CASE_EN from './medical-case/locale/en';
import PRODUCT_EN from './product/locale/en';
import SEARCH_EN from './search/locale/en';
import FILE_EN from './files/locale/en';
import HEALTH_UPDATE_TRACKER_EN from './health-update-tracker/locale/en';
import HAIR_YARD_EN from './hair-yard/locale/en';
import MARKETING_PLATFORM_EN from './marketing-platform/locale/en';
import STUCK_ORDER_EN from './stuck-order/local/en';
import PRODUCT_RECOMMENDATION_EN from './product-recommendation/local/en';
import RENEWAL_REMINDER_EN from './renewal-reminder/locale/en';
import CASE_FIXER_EN from './casefixer/locale/en';

export const resources = {
  en: {
    address: ADDRESS_EN,
    common: COMMON_EN,
    pharmacy: PHARMACY_EN,
    email_tracking: EMAIL_TRACKING_EN,
    customer: CUSTOMER_EN,
    deadLetter: DEAD_LETTER_EN,
    login: LOGIN_EN,
    order: ORDER_EN,
    questions: QUESTIONS_EN,
    subscription: SUBSCRIPTION_EN,
    pending_order: PENDING_ORDER_EN,
    search: SEARCH_EN,
    medical_case: MEDICAL_CASE_EN,
    product: PRODUCT_EN,
    file: FILE_EN,
    health_update_tracker: HEALTH_UPDATE_TRACKER_EN,
    hair_yard: HAIR_YARD_EN,
    marketing_platform: MARKETING_PLATFORM_EN,
    stuck_order: STUCK_ORDER_EN,
    product_recommendation: PRODUCT_RECOMMENDATION_EN,
    renewal_reminder: RENEWAL_REMINDER_EN,
    case_fixer: CASE_FIXER_EN,
  },
};

export const ns = [
  'address',
  'common',
  'pharmacy',
  'customer',
  'login',
  'order',
  'questions',
  'subscription',
  'webhook_mocks',
  'pending_order',
  'product',
  'search',
  'health_update_tracker',
  'hair_yard',
  'marketing_platform',
  'stuck_order',
  'product_recommendation',
  'renewal_reminder',
  'case_fixer',
];

export const defaultNS = 'common';

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const i18n = () =>
  i18next
    // eslint-disable-next-line max-len
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // eslint-disable-next-line max-len
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: 'en',
      debug: true,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      ns,
      defaultNS,
      resources,
    });

export default i18n;
